import { connect } from 'react-redux';

import {
    FooterContainer as SourceFooterContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Footer/Footer.container';
import { RootState } from 'Util/Store/Store.type';
/** @namespace VusaApp/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    header_logo_src: state.ConfigReducer.header_logo_src,
    logo_alt: state.ConfigReducer.logo_alt,
});

/** @namespace VusaApp/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    containerProps() {
        const {
            header_logo_src,
            logo_alt,
        } = this.props;

        return {
            ...super.containerProps(),
            header_logo_src,
            logo_alt,
        };
    }
}

export default connect(mapStateToProps, sourceMapDispatchToProps)(FooterContainer);
